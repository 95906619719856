'use strict';

// const quickViewInclude = require('base/product/quickView');

$(document).ready(function () {
    require('./product/imageSlider').init();
    // require('./product/quickview').onQuickviewReady();
    // quickViewInclude.showQuickview();
    // quickViewInclude.hideDialog();
});
